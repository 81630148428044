import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";

const TeamPage = () => {
  return (
    <Layout>
      <Seo title="Team" pathname="/team" />
      <main>Team Page</main>
    </Layout>
  );
};

export default TeamPage;
